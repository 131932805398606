import { type VariantProps, cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300',
    {
        variants: {
            variant: {
                default: 'bg-neutral-900 text-neutral-50 hover:bg-neutral-900/90 dark:bg-primary dark:text-white dark:hover:bg-primary-hover',
                destructive:
                    'bg-red-500 text-neutral-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-neutral-50 dark:hover:bg-red-900/90',
                outline:
                    'border border-neutral-200 bg-white dark:border-0 hover:bg-neutral-100 hover:text-neutral-900 dark:text-gray-400 dark:bg-[#2c2e32] dark:hover:bg-[#3b3d41] dark:hover:text-white',
                secondary:
                    'bg-neutral-100 text-neutral-900 hover:bg-neutral-100/80 dark:bg-neutral-800 dark:text-neutral-50 dark:hover:bg-neutral-800/80',
                ghost: 
                    'hover:bg-neutral-100 hover:text-neutral-900 dark:hover:bg-[#2c2e32] dark:text-gray-400 dark:hover:text-white',
                link: 
                    'text-neutral-900 underline-offset-4 hover:underline dark:text-neutral-50',
            },
            size: {
                default: 'px-4 py-2.5',
                xs: 'h-7 rounded px-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
